import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";

import { NavigationLink } from "./Links";
import getCurrentLocale from "../utils/getCurrentLocale";

const NavigationContainer = styled.nav`
  font-weight: bold;
  overflow: scroll;
  overflow-x: auto;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.black};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const LinksContainer = styled.div`
  width: max-content;
  padding: 1.5rem 1rem;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.maxWidth}) {
    width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
  }

  @media screen and (min-width: calc(${({ theme }) => theme.maxWidth} + ${({
      theme,
    }) => theme.maxWidthOuterMargin})) {
    padding: 2rem 0;
  }
`;

const NavigationScrollbar: React.FC = () => {
  const data = useStaticQuery(graphql`
    query AllProductCategorySlugsQuery {
      productCategories: allStrapiProductCategory {
        nodes {
          slug
          id: strapiId
          dutchName
          englishName
        }
      }
    }
  `);

  const isEnglish = getCurrentLocale() === "en";
  const productCategories = data.productCategories.nodes;

  return (
    <NavigationContainer>
      <LinksContainer>
        {productCategories.map(({ id, slug, englishName, dutchName }: any) => (
          <NavigationLink
            key={id}
            to={isEnglish ? `/en/${slug}/` : `/${slug}/`}
          >
            {isEnglish ? englishName : dutchName}
          </NavigationLink>
        ))}
      </LinksContainer>
    </NavigationContainer>
  );
};

export default NavigationScrollbar;
