import getCurrentLocale from "./getCurrentLocale";

const getReadablePrice = (price: number): string => {
  const isEnglish = getCurrentLocale() === "en";

  return isEnglish
    ? Intl.NumberFormat("en-EN", {
        style: "currency",
        currency: "EUR",
      }).format(price)
    : Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      }).format(price);
};

export default getReadablePrice;
